<template>
  <div>
    <p v-if="showNomore" class="nomore">暂无数据</p>
    <book-list :set="teamIsmember==1? true :false" :datalist="bookData" v-loading="loading"></book-list>
    <el-pagination
      background
        v-if="total>pageCount"
      @current-change="handleCurrentChange"
      layout="prev, pager, next"
      :page-size.sync="pageCount"
      :current-page="currentPage"
      :total="total"
    ></el-pagination>
     
  </div>
</template>
<script>
import BookList from "@/views/book/components/Booklist.vue";
import { teamBooksRequest } from "@/api/project.js";
export default {
  components: {
    BookList
  },
  data() {
    return {
      bookData: "",
      currentPage:1,
      total:'',
      pageCount:10,
      loading:true,
      showNomore:false
    };
  },
  created() {
    this.getBook(this.tid);
  },
  computed: {
    tid() {
      return this.$route.params.tid || ""; //当前项目id
    },
    teamIsmember() {
      return this.$store.state.teamIsmember;
    }
  },
  watch:{
     tid(newval) {
      this.getBook(newval);
    }
  },
  methods: {
    async getBook(tid,val) {
      try {
        this.loading = true;
        setTimeout(() => {
          this.loading = false;
        }, 3000);
          val=val || '1'
        const result = await teamBooksRequest({
          tid: tid,
          page: val
        });
        if (result.status == 200) {
          this.loading = false;
          this.bookData = result.data.list;
          if(this.bookData.length==0)this.showNomore=true
          this.total=result.data.totalCount
          this.pageCount=result.data.pageCount
        }
      } catch (err) {}
    },
    handleCurrentChange(val) {
      this.getBook(this.tid,val);
    }
  }
};
</script>
<style  lang="less" scoped>
.el-pagination{
text-align: right;
padding:30px 20px;
}
.nomore{
  height:150px;
  line-height: 150px;
  text-align: center;
  font-size: 14px;
  color: #999;
}
</style>